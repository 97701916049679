import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"

export default function Footer() {
  const data = useStaticQuery(graphql`
    query SiteFooter {
      site {
        siteMetadata {
          copyright
          contact
        }
      }
    }
  `)

  const { copyright, contact } = data.site.siteMetadata

  return (
    <footer>
      <p>{copyright}</p>
    </footer>
  )
}
