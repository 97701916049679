import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { FaGithub } from "@react-icons/all-files/fa/FaGithub"

export default function Navbar() {
  const data = useStaticQuery(graphql`
    query SiteHeader {
      site {
        siteMetadata {
          title
          contact
        }
      }
    }
  `)
  const { title, contact } = data.site.siteMetadata

  return (
    <nav>
      <Link className="title" to="/">
        <h1>{title}</h1>
      </Link>
      <div className="links">
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/projects">Projects</Link>
        {/* <Link to="/blog">Blog</Link> */}
        <a
          href="https://github.com/derrickmstrong"
          target="_blank"
          rel="noreferrer"
        >
          <FaGithub className="social" />
        </a>{" "}
        <a
          href="https://www.linkedin.com/in/derrickmstrong/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin className="social" />
        </a>
        <a href={`mailto:${contact}`}>
          <FaEnvelope className="social" />
        </a>
      </div>
    </nav>
  )
}
