import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "./Navbar"
import Footer from "./Footer"
import "../styles/global.css"

export default function Layout({ title = "", children }) {
  return (
    <div className="layout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} - Derrick Strong</title>
      </Helmet>
      <Navbar />
      <div className="content">{children}</div>
      <Footer />
    </div>
  )
}
